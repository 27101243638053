// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-live-chat-tsx": () => import("./../../../src/pages/add-live-chat.tsx" /* webpackChunkName: "component---src-pages-add-live-chat-tsx" */),
  "component---src-pages-ai-index-tsx": () => import("./../../../src/pages/ai/index.tsx" /* webpackChunkName: "component---src-pages-ai-index-tsx" */),
  "component---src-pages-ai-refund-tsx": () => import("./../../../src/pages/ai/refund.tsx" /* webpackChunkName: "component---src-pages-ai-refund-tsx" */),
  "component---src-pages-ai-trial-extension-tsx": () => import("./../../../src/pages/ai/trial-extension.tsx" /* webpackChunkName: "component---src-pages-ai-trial-extension-tsx" */),
  "component---src-pages-ai-upgrade-plan-tsx": () => import("./../../../src/pages/ai/upgrade-plan.tsx" /* webpackChunkName: "component---src-pages-ai-upgrade-plan-tsx" */),
  "component---src-pages-alternatives-tsx": () => import("./../../../src/pages/alternatives.tsx" /* webpackChunkName: "component---src-pages-alternatives-tsx" */),
  "component---src-pages-audience-segmentation-tsx": () => import("./../../../src/pages/audience-segmentation.tsx" /* webpackChunkName: "component---src-pages-audience-segmentation-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-canned-response-templates-tsx": () => import("./../../../src/pages/canned-response-templates.tsx" /* webpackChunkName: "component---src-pages-canned-response-templates-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-conversation-inbox-tsx": () => import("./../../../src/pages/conversation-inbox.tsx" /* webpackChunkName: "component---src-pages-conversation-inbox-tsx" */),
  "component---src-pages-conversational-ai-tsx": () => import("./../../../src/pages/conversational-ai.tsx" /* webpackChunkName: "component---src-pages-conversational-ai-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-crm-tsx": () => import("./../../../src/pages/crm.tsx" /* webpackChunkName: "component---src-pages-crm-tsx" */),
  "component---src-pages-customer-satisfaction-tool-tsx": () => import("./../../../src/pages/customer-satisfaction-tool.tsx" /* webpackChunkName: "component---src-pages-customer-satisfaction-tool-tsx" */),
  "component---src-pages-customer-service-solution-tsx": () => import("./../../../src/pages/customer-service-solution.tsx" /* webpackChunkName: "component---src-pages-customer-service-solution-tsx" */),
  "component---src-pages-demo-book-sales-tsx": () => import("./../../../src/pages/demo/book-sales.tsx" /* webpackChunkName: "component---src-pages-demo-book-sales-tsx" */),
  "component---src-pages-demo-index-tsx": () => import("./../../../src/pages/demo/index.tsx" /* webpackChunkName: "component---src-pages-demo-index-tsx" */),
  "component---src-pages-email-marketing-tsx": () => import("./../../../src/pages/email-marketing.tsx" /* webpackChunkName: "component---src-pages-email-marketing-tsx" */),
  "component---src-pages-email-template-builder-tsx": () => import("./../../../src/pages/email-template-builder.tsx" /* webpackChunkName: "component---src-pages-email-template-builder-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-help-center-tsx": () => import("./../../../src/pages/help-center.tsx" /* webpackChunkName: "component---src-pages-help-center-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-ecommerce-tsx": () => import("./../../../src/pages/industries/ecommerce.tsx" /* webpackChunkName: "component---src-pages-industries-ecommerce-tsx" */),
  "component---src-pages-industries-saas-tsx": () => import("./../../../src/pages/industries/saas.tsx" /* webpackChunkName: "component---src-pages-industries-saas-tsx" */),
  "component---src-pages-live-chat-api-tsx": () => import("./../../../src/pages/live-chat-api.tsx" /* webpackChunkName: "component---src-pages-live-chat-api-tsx" */),
  "component---src-pages-live-chat-customization-tsx": () => import("./../../../src/pages/live-chat-customization.tsx" /* webpackChunkName: "component---src-pages-live-chat-customization-tsx" */),
  "component---src-pages-live-chat-engagement-tsx": () => import("./../../../src/pages/live-chat-engagement.tsx" /* webpackChunkName: "component---src-pages-live-chat-engagement-tsx" */),
  "component---src-pages-live-chat-for-mobile-apps-tsx": () => import("./../../../src/pages/live-chat-for-mobile-apps.tsx" /* webpackChunkName: "component---src-pages-live-chat-for-mobile-apps-tsx" */),
  "component---src-pages-live-chat-for-website-tsx": () => import("./../../../src/pages/live-chat-for-website.tsx" /* webpackChunkName: "component---src-pages-live-chat-for-website-tsx" */),
  "component---src-pages-live-chat-plugin-for-shopify-tsx": () => import("./../../../src/pages/live-chat-plugin-for-shopify.tsx" /* webpackChunkName: "component---src-pages-live-chat-plugin-for-shopify-tsx" */),
  "component---src-pages-live-chat-plugin-for-wordpress-tsx": () => import("./../../../src/pages/live-chat-plugin-for-wordpress.tsx" /* webpackChunkName: "component---src-pages-live-chat-plugin-for-wordpress-tsx" */),
  "component---src-pages-live-chat-templates-tsx": () => import("./../../../src/pages/live-chat-templates.tsx" /* webpackChunkName: "component---src-pages-live-chat-templates-tsx" */),
  "component---src-pages-live-chat-tsx": () => import("./../../../src/pages/live-chat.tsx" /* webpackChunkName: "component---src-pages-live-chat-tsx" */),
  "component---src-pages-live-demo-ai-tsx": () => import("./../../../src/pages/live-demo/ai.tsx" /* webpackChunkName: "component---src-pages-live-demo-ai-tsx" */),
  "component---src-pages-lp-ai-tsx": () => import("./../../../src/pages/lp/ai.tsx" /* webpackChunkName: "component---src-pages-lp-ai-tsx" */),
  "component---src-pages-lp-cs-platform-tsx": () => import("./../../../src/pages/lp/cs-platform.tsx" /* webpackChunkName: "component---src-pages-lp-cs-platform-tsx" */),
  "component---src-pages-lp-intercom-tsx": () => import("./../../../src/pages/lp/intercom.tsx" /* webpackChunkName: "component---src-pages-lp-intercom-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-marketing-automation-tool-tsx": () => import("./../../../src/pages/marketing-automation-tool.tsx" /* webpackChunkName: "component---src-pages-marketing-automation-tool-tsx" */),
  "component---src-pages-marketing-funnel-tsx": () => import("./../../../src/pages/marketing-funnel.tsx" /* webpackChunkName: "component---src-pages-marketing-funnel-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-net-promoter-score-tsx": () => import("./../../../src/pages/net-promoter-score.tsx" /* webpackChunkName: "component---src-pages-net-promoter-score-tsx" */),
  "component---src-pages-not-found-tsx": () => import("./../../../src/pages/not-found.tsx" /* webpackChunkName: "component---src-pages-not-found-tsx" */),
  "component---src-pages-o-dts-tsx": () => import("./../../../src/pages/o/dts.tsx" /* webpackChunkName: "component---src-pages-o-dts-tsx" */),
  "component---src-pages-o-f-6-s-tsx": () => import("./../../../src/pages/o/f6s.tsx" /* webpackChunkName: "component---src-pages-o-f-6-s-tsx" */),
  "component---src-pages-o-github-tsx": () => import("./../../../src/pages/o/github.tsx" /* webpackChunkName: "component---src-pages-o-github-tsx" */),
  "component---src-pages-o-mastercard-tsx": () => import("./../../../src/pages/o/mastercard.tsx" /* webpackChunkName: "component---src-pages-o-mastercard-tsx" */),
  "component---src-pages-o-revolut-tsx": () => import("./../../../src/pages/o/revolut.tsx" /* webpackChunkName: "component---src-pages-o-revolut-tsx" */),
  "component---src-pages-o-tag-tsx": () => import("./../../../src/pages/o/tag.tsx" /* webpackChunkName: "component---src-pages-o-tag-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-smart-assignment-rules-tsx": () => import("./../../../src/pages/smart-assignment-rules.tsx" /* webpackChunkName: "component---src-pages-smart-assignment-rules-tsx" */),
  "component---src-pages-startups-tsx": () => import("./../../../src/pages/startups.tsx" /* webpackChunkName: "component---src-pages-startups-tsx" */),
  "component---src-pages-stories-team-system-tsx": () => import("./../../../src/pages/stories/team-system.tsx" /* webpackChunkName: "component---src-pages-stories-team-system-tsx" */),
  "component---src-pages-success-stories-tsx": () => import("./../../../src/pages/success-stories.tsx" /* webpackChunkName: "component---src-pages-success-stories-tsx" */),
  "component---src-pages-survey-templates-tsx": () => import("./../../../src/pages/survey-templates.tsx" /* webpackChunkName: "component---src-pages-survey-templates-tsx" */),
  "component---src-pages-surveys-tsx": () => import("./../../../src/pages/surveys.tsx" /* webpackChunkName: "component---src-pages-surveys-tsx" */),
  "component---src-pages-team-reports-tsx": () => import("./../../../src/pages/team-reports.tsx" /* webpackChunkName: "component---src-pages-team-reports-tsx" */),
  "component---src-pages-templates-for-email-marketing-tsx": () => import("./../../../src/pages/templates-for-email-marketing.tsx" /* webpackChunkName: "component---src-pages-templates-for-email-marketing-tsx" */),
  "component---src-pages-templates-tsx": () => import("./../../../src/pages/templates.tsx" /* webpackChunkName: "component---src-pages-templates-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-try-customerly-best-intercom-alternative-tsx": () => import("./../../../src/pages/try-customerly/best-intercom-alternative.tsx" /* webpackChunkName: "component---src-pages-try-customerly-best-intercom-alternative-tsx" */),
  "component---src-pages-video-live-chat-tsx": () => import("./../../../src/pages/video-live-chat.tsx" /* webpackChunkName: "component---src-pages-video-live-chat-tsx" */),
  "component---src-pages-webinars-tsx": () => import("./../../../src/pages/webinars.tsx" /* webpackChunkName: "component---src-pages-webinars-tsx" */),
  "component---src-templates-alternative-tsx": () => import("./../../../src/templates/alternative.tsx" /* webpackChunkName: "component---src-templates-alternative-tsx" */),
  "component---src-templates-author-index-tsx": () => import("./../../../src/templates/author/index.tsx" /* webpackChunkName: "component---src-templates-author-index-tsx" */),
  "component---src-templates-blog-author-index-tsx": () => import("./../../../src/templates/blog/author/index.tsx" /* webpackChunkName: "component---src-templates-blog-author-index-tsx" */),
  "component---src-templates-blog-category-index-tsx": () => import("./../../../src/templates/blog/category/index.tsx" /* webpackChunkName: "component---src-templates-blog-category-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blog/post/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-success-story-index-tsx": () => import("./../../../src/templates/success-story/index.tsx" /* webpackChunkName: "component---src-templates-success-story-index-tsx" */),
  "component---src-templates-templates-canned-response-index-tsx": () => import("./../../../src/templates/templates/canned-response/index.tsx" /* webpackChunkName: "component---src-templates-templates-canned-response-index-tsx" */),
  "component---src-templates-templates-chat-index-tsx": () => import("./../../../src/templates/templates/chat/index.tsx" /* webpackChunkName: "component---src-templates-templates-chat-index-tsx" */),
  "component---src-templates-templates-email-index-tsx": () => import("./../../../src/templates/templates/email/index.tsx" /* webpackChunkName: "component---src-templates-templates-email-index-tsx" */),
  "component---src-templates-templates-survey-index-tsx": () => import("./../../../src/templates/templates/survey/index.tsx" /* webpackChunkName: "component---src-templates-templates-survey-index-tsx" */),
  "component---src-templates-term-tsx": () => import("./../../../src/templates/term.tsx" /* webpackChunkName: "component---src-templates-term-tsx" */),
  "component---src-templates-webinar-index-tsx": () => import("./../../../src/templates/webinar/index.tsx" /* webpackChunkName: "component---src-templates-webinar-index-tsx" */)
}

