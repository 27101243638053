export const BASE_DOMAIN = "customerly.io";
export const API_BACKEND_URL = `https://api-private.${BASE_DOMAIN}/v3`;
export const APP_URL = `https://app.${BASE_DOMAIN}`;
export const LANDING_PAGE_URL = `https://lp.${BASE_DOMAIN}`;
export const SIGNUP_URL = `${LANDING_PAGE_URL}/free-trial`;
export const USE_TEMPLATE_URL = `${APP_URL}/use-template`;
export const USE_EMAIL_TEMPLATE_BUILDER = `${APP_URL}/email-template-builder/welcome`;
export const PICTURES_ENDPOINT = `https://pictures.${BASE_DOMAIN}`;
export const ALTERNATIVES_PATH = "/alternatives";
export const DEMO_PATH = `${LANDING_PAGE_URL}/demo`;
export const GLOSSARY_PATH = "/glossary";
export const BLOG_PATH = "/blog";
export const SUCCESS_STORIES_PATH = "/success-stories";
export const TEMPLATES_PATH = "/templates";
export const EMAIL_TEMPLATES_PATH = "/templates-for-email-marketing";
export const CANNED_RESPONSE_TEMPLATES_PATH = "/canned-response-templates";
export const SURVEY_TEMPLATES_PATH = "/survey-templates";
export const COMMUNITY_PATH = "/community";
export const CHAT_TEMPLATES_PATH = "/live-chat-templates";
export const WORKFLOW_TEMPLATES_PATH = "/marketing-automation-templates";
export const WEBINARS_PATH = "/webinars";
export const MONOLANGUAGE_PAGES = [
  "/cookies",
  "/gdpr",
  "/privacy",
  "/terms-of-use",
  "/404",
  "/not-found",
  BLOG_PATH,
  GLOSSARY_PATH,
  DEMO_PATH,
  WEBINARS_PATH,
  SUCCESS_STORIES_PATH,
  TEMPLATES_PATH,
  ALTERNATIVES_PATH,
  EMAIL_TEMPLATES_PATH,
  CANNED_RESPONSE_TEMPLATES_PATH,
  SURVEY_TEMPLATES_PATH,
  COMMUNITY_PATH,
  CHAT_TEMPLATES_PATH,
  WORKFLOW_TEMPLATES_PATH,
];
